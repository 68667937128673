import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import HomePage from "./pages/Home";
import AboutPage from "./pages/About";
import ResponsiveAppBar from "./components/ResponsiveAppBar";
import Footer from "./components/Footer";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import ContactPage from "./pages/Contact";
import Services from "./pages/Services";

export interface IApplicationProps {}

const Application: React.FC<IApplicationProps> = (props) => {
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  return (
    <ThemeProvider theme={darkTheme}>
      <ResponsiveAppBar />
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="home" element={<HomePage />}></Route>
          <Route path="hem" element={<HomePage />}></Route>
          <Route path="about" element={<AboutPage />}></Route>
          <Route path="om" element={<AboutPage />}></Route>
          <Route path="contact" element={<ContactPage />}></Route>
          <Route path="kontakt" element={<ContactPage />}></Route>
          <Route path="services" element={<Services />}></Route>
          <Route path="tjänster" element={<Services />}></Route>
          <Route path='/offer' Component={() => {
            window.location.href = 'https://www.autoexperten.se/bilservice/';
            return null;
          }}/>
          <Route path='/offert' Component={() => {
            window.location.href = 'https://www.autoexperten.se/bilservice/';
            return null;
          }}/>
        </Routes>
        <Footer />
      </BrowserRouter>
    </ThemeProvider>
  );
};

export default Application;
