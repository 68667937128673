import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import HomeIcon from "@mui/icons-material/Home";
import PhoneIcon from "@mui/icons-material/Phone";
import map from "../pictures/map.jpg";

export interface IAboutPageProps {}

const ContactPage: React.FC<IAboutPageProps> = (props) => {
  return (
    <Box>
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography
                display={"flex"}
                justifyContent={"center"}
                variant={"h4"}
                mb={4}
              >
                Kontakta oss
              </Typography>
            </Grid>
            <Grid item xs={12} md={2}></Grid>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Box display={"flex"}>
                    <HomeIcon />
                    <Link
                      ml={2}
                      pb={5}
                      variant={"h6"}
                      href={`https://www.google.se/maps/place/Harvstigen+6,+645+41+Str%C3%A4ngn%C3%A4s/@59.3522533,17.028598,17z/data=!3m1!4b1!4m6!3m5!1s0x465efc381027795b:0x747631caa1424562!8m2!3d59.3522533!4d17.0307867!16s%2Fg%2F11cnd8yy5y`}
                    >
                      Harvstigen 6, 645 41 Strängnäs
                    </Link>
                  </Box>
                  <Box display={"flex"}>
                    <PhoneIcon />
                    <Link
                      variant={"h6"}
                      ml={2}
                      pb={5}
                      href={`tel:+46152711085"`}
                    >
                      0152-711 085
                    </Link>
                  </Box>
                  <Box display={"flex"}>
                    <EmailIcon />
                    <Link
                      ml={2}
                      href={`mailto:motorkonsult@autoexperten.se`}
                      variant={"h6"}
                    >
                      motorkonsult@autoexperten.se
                    </Link>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
            <Grid item xs={12} md={6}>
              <img width={"100%"} src={map} alt={"Öppen motorhuv"} />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default ContactPage;
