import React from "react";
import ReactPlayer from "react-player";
import {
  Box,
  Card,
  CardContent,
  Container,
  Grid,
  Link,
  Typography,
} from "@mui/material";
import MkLogoStandingWhite from "../pictures/Standing/mk-logo-standing-white.svg";

export interface IHomePageProps {}

const HomePage: React.FC<IHomePageProps> = (props) => {
  return (
    <Box>
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <Grid container>
            <Grid item lg={3} xs={12}>
              <Box sx={{ mb: { lg: 0, xs: 1 }, mr: { lg: 1, xs: 0 } }}>
                <Box sx={{ mb: { lg: 8, xs: 2 } }}>
                  <img src={MkLogoStandingWhite} alt="Motorkonsult logo" />
                </Box>
                <Card>
                  <CardContent>
                    <Typography variant={"body1"}>
                      Välkommen till Motorkonsult i Strängnäs!
                    </Typography>
                    <Typography
                      sx={{ textTransform: "uppercase" }}
                      variant={"body2"}
                      mt={2}
                    >
                      Autoexperten verkstad
                    </Typography>
                    <Typography mt={2}>
                      <Link variant={"h6"} href={`tel:+46152711085"`}>
                        0152-711 085
                      </Link>
                    </Typography>
                    <Typography variant={"h6"} mt={2}>
                      Harvstigen 6, 645 41 Strängnäs
                    </Typography>
                  </CardContent>
                </Card>
              </Box>
            </Grid>
            <Grid item lg={9} xs={12}>
              <Box sx={{ mt: { lg: 0, xs: 2 }, mb: { lg: 0, xs: 2 } }}>
                <ReactPlayer
                  url="../videos/AX-film1-.mp4"
                  playing={true}
                  loop={true}
                  width="640"
                  height="360"
                  controls={false}
                  volume={0}
                  muted={true}
                />
              </Box>
            </Grid>
            <Grid mt={3} item xs={12}>
              <Card>
                <CardContent>
                  <Box>
                    <Typography variant={"body1"} align={"center"}>
                      Som ansluten bilverkstad till Autoexperten kommer vi kunna
                      erbjuda service och reparationer av alla bilmärken.
                    </Typography>
                    <Typography
                      mt={2}
                      fontWeight={"bold"}
                      variant={"body1"}
                      align={"center"}
                    >
                      Välkommen till oss!
                    </Typography>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default HomePage;
