import Box from "@mui/material/Box";
import * as React from "react";
import Typography from "@mui/material/Typography";
import { Facebook, Instagram } from "@mui/icons-material";
import { Container, Grid, IconButton } from "@mui/material";
// import Godkand from "../pictures/godkand-bilverkstad.jpg";
import autoexperten from "../pictures/autoexperten-white-logo.webp";

export interface IFooterProps {}

function Copyright() {
  return (
    <Container maxWidth="xl">
      <Grid container>
        <Grid
          item
          lg={4}
          xs={12}
          sx={{
            display: "flex",
            justifyContent: { lg: "left", xs: "center" },
            alignItems: { lg: "left", xs: "center" },
            mb: { lg: 0, xs: 2 },
          }}
        >
          <img width={"200px"} src={autoexperten} alt={"Godkänd bilverkstad"} />
        </Grid>
        <Grid item lg={4} xs={12}>
          <Box display={"flex"} justifyContent={"center"} alignItems={"center"}>
            <Typography
              mr={1}
              variant="body2"
              color="text.secondary"
              align="center"
            >
              {"Copyright © Motorkonsult Strängnäs AB "}
              {new Date().getFullYear()}
              {"."}
            </Typography>
            <IconButton
              href={"https://www.facebook.com/profile.php?id=100090476400623"}
            >
              <Facebook />
            </IconButton>
            <IconButton
              href={"https://www.instagram.com/motorkonsult_strangnas_ab/"}
            >
              <Instagram />
            </IconButton>
          </Box>
        </Grid>
        {/*<Grid*/}
        {/*  item*/}
        {/*  lg={4}*/}
        {/*  xs={12}*/}
        {/*  sx={{*/}
        {/*    display: "flex",*/}
        {/*    justifyContent: { lg: "right", xs: "center" },*/}
        {/*    alignItems: { lg: "right", xs: "center" },*/}
        {/*    mt: { lg: 0, xs: 2 },*/}
        {/*  }}*/}
        {/*>*/}
        {/*  <img width={"200px"} src={Godkand} alt={"Godkänd bilverkstad"} />*/}
        {/*</Grid>*/}
      </Grid>
    </Container>
  );
}

const Application: React.FC<IFooterProps> = (props) => {
  return (
    <Box sx={{ bgcolor: "background.paper", p: 6 }} component="footer">
      <Copyright />
    </Box>
  );
};
export default Application;
