import React from "react";
import {
  Box,
  Card,
  CardContent,
  Container,
  Divider,
  Grid,
  Typography,
} from "@mui/material";
import MkLogoLayingWhite from "../pictures/Laying/mk-logo-laying-white.svg";
import hjulinstallning from "../pictures/hjulinstallning.jpg";
import reparation from "../pictures/bilservice-reparation.jpg";
import dackbyte from "../pictures/dackbyte-2.jpg";
import service from "../pictures/dackbyte.jpg";
import felsokning from "../pictures/open-hood-small-gray.jpg";
import Button from "@mui/material/Button";
export interface IAboutPageProps {}

const Services: React.FC<IAboutPageProps> = (props) => {
  return (
    <Box>
      <Container maxWidth="xl">
        <Box sx={{ minHeight: "75vh" }}>
          <Grid container>
            <Grid item xs={12}>
              <Box>
                <img src={MkLogoLayingWhite} alt="Motorkonsult logo" />
              </Box>
            </Grid>
            <Grid display={"flex"} justifyContent={"center"} item xs={12}>
              <Typography variant={"h4"} m={2}>
                Våra tjänster
              </Typography>
            </Grid>
            <Grid item lg={2} md={3} sm={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h6"}
                  >
                    Hjulinställning
                  </Typography>
                  <Typography variant={"body2"} mt={1} mb={3}>
                    Känner du att bilen drar lite åt höger eller vänster? Är
                    däcken ojämnt slitna? Har du råkat köra emot något?
                  </Typography>
                  <Button href={"/Services#hjulinstallning"} variant="outlined">
                    Läs mer
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={3} sm={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h6"}
                  >
                    Reparation
                  </Typography>
                  <Typography variant={"body2"} mt={1} mb={3}>
                    Lämna din bil tryggt och säkert hos oss. Vi har kunskapen
                    och använder alltid reservdelar av högsta kvalitet.
                  </Typography>
                  <Button href={"/Services#reparation"} variant="outlined">
                    Läs mer
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={3} sm={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h6"}
                  >
                    Däck & fälg
                  </Typography>
                  <Typography variant={"body2"} mt={1} mb={3}>
                    Vi har ett brett urval av både sommar- och vinterdäck i
                    flera prissegment till din bil. Vi erbjuder även däckhotell.
                  </Typography>
                  <Button href={"/Services#dack"} variant="outlined">
                    Läs mer
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={3} sm={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h6"}
                  >
                    Service
                  </Typography>
                  <Typography variant={"body2"} mt={1} mb={3}>
                    Som Autoexperten-verkstad erbjuder vi 12 månaders fri
                    assistansförsäkring vid underhållsservice.
                  </Typography>
                  <Button href={"/Services#service"} variant="outlined">
                    Läs mer
                  </Button>
                </CardContent>
              </Card>
            </Grid>
            <Grid item lg={2} md={3} sm={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h6"}
                  >
                    Felsökning
                  </Typography>
                  <Typography variant={"body2"} mt={1} mb={3}>
                    Vi har rätt utrustning och utbildade mekaniker för att
                    snabbt kunna göra en korrekt felsökning på din bil.
                  </Typography>
                  <Button href={"/Services#felsokning"} variant="outlined">
                    Läs mer
                  </Button>
                </CardContent>
              </Card>
            </Grid>

            <Grid id="hjulinstallning" item xs={12} mb={6} mt={10}>
              <Divider />
            </Grid>
            <Grid item md={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h4"}
                  >
                    Hjulinställning
                  </Typography>
                  <Typography variant={"body1"} mt={3} mb={3}>
                    Drar din bil lite åt höger eller vänster? Är däcken ojämnt
                    slitna? Har du råkat köra emot något? Då är det dags att
                    lämna in din bil för hjulinställning.
                  </Typography>
                  <Typography variant={"body1"} mt={1} mb={5}>
                    En hjulinställning kräver en utbildad mekaniker med tillgång
                    till den avancerade utrustning moderna bilar behöver och de
                    korrekta mätvärdena för din bilmodell. Vi rekommenderar att
                    en kontroll av hjulinställningen bör göras minst en gång om
                    året.
                  </Typography>
                  <Box display={"flex"} justifyContent={"right"}>
                    <Button href={"Contact"} variant="contained">
                      Kontakt
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              m={2}
              display={"flex"}
              justifyContent={"right"}
            >
              <img
                height={"600px"}
                src={hjulinstallning}
                alt={"Working on the car wheel"}
              />
            </Grid>

            <Grid id={"reparation"} item xs={12} mb={6} mt={6}>
              <Divider />
            </Grid>

            <Grid item md={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h4"}
                  >
                    Reparation
                  </Typography>
                  <Typography variant={"body1"} mt={3} mb={5}>
                    Vi reparerar alla bilar med bibehållna garantier. Vi följer
                    SFVF’s reparationsvillkor. Inget jobb är för stort eller för
                    litet.
                  </Typography>
                  <Box display={"flex"} justifyContent={"right"}>
                    <Button href={"Contact"} variant="contained">
                      Kontakt
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              m={2}
              display={"flex"}
              justifyContent={"right"}
            >
              <img
                height={"600px"}
                src={reparation}
                alt={"Working on the car wheel"}
              />
            </Grid>

            <Grid id="dack" item xs={12} mb={6} mt={6}>
              <Divider />
            </Grid>

            <Grid item md={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h4"}
                  >
                    Däck, fälg och däckhotell
                  </Typography>
                  <Typography variant={"body1"} mt={3} mb={3}>
                    Vi har ett brett urval av både sommar- och vinterdäck i
                    flera prissegment till din bil. Utöver försäljning har våra
                    verkstäder även utrustning för att balansera och lägga om
                    däck på fälg. Självklart hjälper vi dig laga ett trasigt
                    däck om det är enklaste lösningen och om det inte påverkar
                    din bils säkerhet.
                  </Typography>
                  <Typography variant={"body1"} mt={1} mb={5}>
                    Hos oss kan du också förvara dina däck efter dina behov och
                    önskemål. Om vi upptäcker att skicket på dina däck är dåligt
                    under någon av våra kontroller tar vi kontakt med dig i god
                    tid innan däckbytet och stämmer av kommande däckbehov.
                  </Typography>
                  <Box display={"flex"} justifyContent={"right"}>
                    <Button href={"Contact"} variant="contained">
                      Kontakt
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              m={2}
              display={"flex"}
              justifyContent={"right"}
            >
              <img
                height={"600px"}
                src={dackbyte}
                alt={"Working on the car wheel"}
              />
            </Grid>

            <Grid id="service" item xs={12} mb={6} mt={6}>
              <Divider />
            </Grid>

            <Grid item md={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h4"}
                  >
                    Bilservice
                  </Typography>
                  <Typography
                    fontWeight={"bold"}
                    variant={"body2"}
                    mt={1}
                    mb={3}
                  >
                    Serva bilen hos oss
                  </Typography>
                  <Typography variant={"body1"} mt={3} mb={5}>
                    Vid bilservice hos oss som Autoexperten-verkstad får du:
                    <ul>
                      <p></p>
                      <li>
                        Bilservice utförd enligt biltillverkarens serviceschema.
                      </li>
                      <p></p>
                      <li>
                        Våra fordonsmekaniker utbildas kontinuerligt för att
                        kunna vara uppdaterade på nya bilmodeller, tekniker,
                        verktyg och tillbehör.
                      </li>
                      <p></p>
                      <li>
                        Vi reparerar och servar din bil på ett fackmannamässigt
                        sätt enligt tillverkarens instruktioner vilket inte
                        påverkar nybilsgarantin.
                      </li>
                      <p></p>
                      <li>
                        enligt tillverkarens instruktioner vilket inte påverkar
                        nybilsgarantin.
                      </li>
                      <p></p>
                      <li>
                        12 månaders fri Assistansförsäkring vid
                        underhållsservice.
                      </li>
                      <p></p>
                      <li>
                        Möjlighet att dela upp betalningen i upp till 24 månader
                        räntefritt.
                      </li>
                    </ul>
                  </Typography>
                  <Box display={"flex"} justifyContent={"right"}>
                    <Button href={"Contact"} variant="contained">
                      Kontakt
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              m={2}
              display={"flex"}
              justifyContent={"right"}
            >
              <img
                height={"600px"}
                src={service}
                alt={"Working on the car wheel"}
              />
            </Grid>

            <Grid id="felsokning" item xs={12} mb={6} mt={6}>
              <Divider />
            </Grid>

            <Grid item md={5} xs={12} m={2}>
              <Card>
                <CardContent>
                  <Typography
                    sx={{ textTransform: "uppercase" }}
                    variant={"h4"}
                  >
                    Felsökning
                  </Typography>
                  <Typography
                    fontWeight={"bold"}
                    variant={"body2"}
                    mt={1}
                    mb={3}
                  >
                    Vi hittar felet
                  </Typography>
                  <Typography variant={"body1"} mt={3} mb={5}>
                    För avancerad felsökning och diagnostik använder vi
                    toppmodern diagnostikutrustning från världsledande
                    tillverkare. Tillsammans med konstant uppdaterad
                    servicedata, teknisk information och kontinuerlig utbildning
                    ger detta oss rätt verktyg för att göra det jobb du som kund
                    förväntar dig.
                  </Typography>
                  <Box display={"flex"} justifyContent={"right"}>
                    <Button href={"Contact"} variant="contained">
                      Kontakt
                    </Button>
                  </Box>
                </CardContent>
              </Card>
            </Grid>

            <Grid
              item
              md={5}
              xs={12}
              m={2}
              display={"flex"}
              justifyContent={"right"}
            >
              <img
                height={"600px"}
                src={felsokning}
                alt={"Working on the car wheel"}
              />
            </Grid>
            <Grid item xs={12} mb={6} mt={6}>
              <Divider />
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  );
};

export default Services;
