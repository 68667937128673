import React from "react";
import { Box, Container, Typography } from "@mui/material";
import MkLogoStandingWhite from "../pictures/Standing/mk-logo-standing-white.svg";

export interface IAboutPageProps {}

const AboutPage: React.FC<IAboutPageProps> = (props) => {
  return (
    <Box>
      <Container maxWidth="md">
        <Box sx={{ minHeight: "75vh" }}>
          <img src={MkLogoStandingWhite} alt="Motorkonsult logo" />
          <Typography mt={2} variant={"body1"} align={"center"}>
            Motorkonsult ägs och drivs av Andreas Boström som har arbetat länge
            i verkstadsbranschen. Under alla år har han arbetat som
            verkstadscoach, verkstadschef. Under dom sista 7 åren har han
            arbetat som verkstadsutvecklare där han coachat verkstäder med
            ekonomi, kompetensutveckling, kundvård, marknadsföring, och inte
            minst bransch- och lagkrav. Och det var där idén föddes. Varför inte
            öppna upp en egen verkstad med dom kunskaperna och brinnande
            intresset, där man får fackmässigt arbete, diagnostik,
            konkurrenskraftiga priser, tekniskt kunnande och ett personligt
            bemötande som vi hoppas ska överträffa dina förväntningar.
          </Typography>
        </Box>
      </Container>
    </Box>
  );
};

export default AboutPage;
